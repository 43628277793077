<template>
    <CCol>
        <CCard>
            <CCardHeader>
                <div class="row">
                    <CCol sm="6" class="align-self-center">
                        <strong>{{ $lang.educations.crud.merge }}</strong>
                    </CCol>
                </div>
            </CCardHeader>
            <CCardBody>
                <CAlert :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
                    {{ err_msg }}
                </CAlert>
                <CRow>
                         <CCol sm="12" md="12" lg="12">
                        <p><span class="custome-labal"><b>{{ $lang.educations.form.newtitle }} </b>:</span> <span>
              {{ detail.title }}
            </span></p>
                    </CCol>
                </CRow>
                <CForm @submit.prevent="onSubmit">
                    <CRow>
                        <CCol md="6">
                            <div class="form-group">
                                <label class="form__label">{{ $lang.educations.form.duplicate }}</label>
                                <v-select :options="educationsLists" v-model="educationIds"></v-select>
                                <!--                <small class="error" v-if="$v.plan.industryId.$error && !$v.plan.industryId.required">{{ $lang.plan.validation.required.industryId }}</small>-->
                            </div>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol col="6" class="text-left">
                            <CButton color="primary" :disabled="submitted" class="px-4" type="submit">
                                {{ $lang.common.general.submit }}
                            </CButton>
                        </CCol>
                    </CRow>
                </CForm>
            </CCardBody>
        </CCard>
    </CCol>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {educations} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import store from "../../store/store";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
    name: "MergeEducation",
    mixins: [Mixin],
    data() {
        return {
            industryLists: [],
            messageColor: "success",
            submitted: false,
            err_msg: "",
            industry: "",
            alertMessage: "test",
            showAlert: false,
            dismissCountDown: 0,
            dismissCountDownS: 0,
            self: this,
            msg: "",
            editId: "",
            items: [],
            educationsLists: [],
            detail: [],
            educationIds: [],
            activePage: 1,
            module: educations,
            skills: {
                title: "",
                isActive: 1,
                isDefault: 0,
            },
        };
    },
    validations: {
        educationIds: {
            required,
        }
    },
    mounted() {
        let self = this;
        self.dismissCountDown = 0;
        self.dismissCountDownS = 0;
        self.editId = this.$route.params.id;
        axios.get(this.viewUrlApi(this.module, self.editId), self.model)
            .then(function (response) {
                if (response) {
                    const responseData = response.data.data;
                    self.detail = responseData.details;
                    responseData.list.map(function (value, key) {
                        self.educationsLists.push({value: value.id, label: value.title});
                    });
                } else {
                    self.detail = [];
                }
            });
        store.commit("showLoader", false);
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                if (route.query && route.query.page) {
                    this.activePage = Number(route.query.page);
                }
            },
        },
    },
    methods: {
        onSubmit() {
            let self = this;
            self.submitted = true; //Disable Button
            this.submitStatus = "PENDING";
            setTimeout(() => {
                let self = this;
                const postData = {
                    'newId': this.$route.params.id,
                    'oldId': self.educationIds.value,
                };
                axios.post('educations/merge', postData)
                    .then(function (response) {
                        self.submitted = false; //Enable Button
                        if (response.data.code === 200) {
                            self.alertMessage = response.data.message;
                            self.messageColor = "success";
                            self.dismissCountDownS = 10;
                        } else {
                            self.err_msg = response.data.message;
                            self.dismissCountDown = 10;
                        }
                        self.$router.push({path: `/educations/other`});
                    })
                    .catch(function (error) {
                        let data;
                        if (error.response.data.error) {
                            data = error.response.data.error.toString();
                        } else {
                            data = error.response.data.message;
                        }
                        self.err_msg = data;
                        self.dismissCountDown = 10;
                        self.submitted = false; //Enable Button
                    });
            }, 500);
        }
    },
};
</script>
